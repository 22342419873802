import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SandboxComponent } from './pages/sandbox/sandbox.component';
import { SkulptComponent } from './runtimes/skulpt/skulpt.component';
import { KarelComponent } from './runtimes/karel/karel.component';
import { CodepanComponent } from './runtimes/codepan/codepan.component';

@NgModule({
  declarations: [
    AppComponent,
    SandboxComponent,
    SkulptComponent,
    KarelComponent,
    CodepanComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
