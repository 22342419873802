export function getRuntimeTypeForLanguage(language: string) {
  switch (language) {
    case 'python3-turtle':
    case 'python2-turtle':
      return 'skulpt';
    case 'karel-java':
    case 'karel-python':
      return 'karel';
    default:
      return 'codepan';
  }
}
