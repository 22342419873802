import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IRuntimeComponent} from '../../pages/sandbox/models';
import {IExecuteCodePayload} from '@exlinc/cc-web-exec-sdk/dist/models';

@Component({
  selector: 'app-skulpt',
  templateUrl: './skulpt.component.html',
  styleUrls: ['./skulpt.component.scss']
})
export class SkulptComponent implements OnInit, IRuntimeComponent {
  // @ts-ignore
  Sk = window.Sk;
  python3 = true;
  showCanvas = true;
  currentFiles = {};
  // tslint:disable-next-line:variable-name
  _language = 'python2-turtle';
  @Input()
  set language(l: string) {
    this._setLang(l);
  }

  @ViewChild('outputPre')
  outputPre: ElementRef;
  @ViewChild('outputCanvas')
  outputCanvas: ElementRef;

  constructor() {
  }

  ngOnInit(): void {
  }

  _setLang(l: string) {
    if (!l) {
      return;
    }
    this._language = l;
    this.python3 = this._language.startsWith('python3');
    this.showCanvas = this._language.indexOf('turtle') > -1;
  }

  getRuntimeType() {
    return 'skulpt';
  }

  async runCode(payload: IExecuteCodePayload) {
    // var prog = document.getElementById("yourcode").value;
    // var mypre = document.getElementById("output");
    // mypre.innerHTML = '';
    // Sk.pre = "output";
    this._setLang(payload.language);
    this.Sk.configure({
      output: this.onStdout.bind(this),
      read: this.builtinRead.bind(this),
      __future__: this.python3 ? this.Sk.python3 : undefined,
    });
    (this.Sk.TurtleGraphics || (this.Sk.TurtleGraphics = {})).target = this.outputCanvas.nativeElement;
    try {
      this.currentFiles = payload.files;
      await this.Sk.misceval.asyncToPromise(() => {
        return this.Sk.importMainWithBody('<stdin>', false, payload.files[payload.defaultFileName], true);
      });
      this.currentFiles = {};
    } catch (err) {
      // TODO present error to user
      console.error(err);
    }
  }

  onStdout(text) {
    console.log('[SK-STDOUT] ', text);
  }

  builtinRead(x) {
    if (this.currentFiles && this.currentFiles[x] !== undefined) {
      return this.currentFiles[x];
    }
    if (this.Sk.builtinFiles === undefined || this.Sk.builtinFiles.files[x] === undefined) {
      throw new Error('File not found: \'' + x + '\'');
    }
    return this.Sk.builtinFiles.files[x];
  }

}
