import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CCWebExecClient} from '@exlinc/cc-web-exec-sdk/dist/cc-web-exec-client';
import {IExecuteCodePayload} from '@exlinc/cc-web-exec-sdk/dist/models';
import {EVENT_TYPE_EXECUTE_CODE} from '@exlinc/cc-web-exec-sdk/dist/consts';
import {IRuntimeComponent} from './models';
import {getRuntimeTypeForLanguage} from './runtimes';

@Component({
  selector: 'app-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.scss']
})
export class SandboxComponent implements OnInit, OnDestroy {
  client: CCWebExecClient = null;
  language = '';
  runtimeType = '';
  waitingForRuntime = false;
  pendingExecPayload: IExecuteCodePayload = null;

  // tslint:disable-next-line:variable-name
  _runtime = null;
  @ViewChild('runtime', { static: false })
  set runtime(r: IRuntimeComponent) {
    this._runtime = r;
    if (r && this.waitingForRuntime && this.pendingExecPayload && this.readyToRunForPayload()) {
      this.runCodeAfterChecks(this.pendingExecPayload);
    }
  }

  constructor() {
  }

  ngOnInit(): void {
    this.client = new CCWebExecClient();
    this.client.subscribe<IExecuteCodePayload>(EVENT_TYPE_EXECUTE_CODE, (event, payload) => {
      this.handleExecuteCodeRequest(payload);
    });
  }

  ngOnDestroy(): void {
    this.client.dispose();
  }

  handleExecuteCodeRequest(payload: IExecuteCodePayload) {
    this.language = payload.language;
    this.runtimeType = getRuntimeTypeForLanguage(this.language);
    if (!this.runtimeType) {
      // TODO see if there is a good way to report this error to the user
      console.error('Invalid/unknown language: ', this.language);
      return;
    }
    if (!this.readyToRunForPayload()) {
      this.waitingForRuntime = true;
      this.pendingExecPayload = payload;
      return;
    }
    this.runCodeAfterChecks(payload);
  }

  readyToRunForPayload() {
    return this._runtime && this._runtime.getRuntimeType && this._runtime.getRuntimeType() === this.runtimeType;
  }

  runCodeAfterChecks(payload: IExecuteCodePayload) {
    this.waitingForRuntime = false;
    this.pendingExecPayload = null;
    if (!this._runtime || !this._runtime.runCode) {
      // fail as this should've already been checked -- just to avoid an exception
      console.error('Runtime invalid in runCodeAfterChecks: ', this._runtime);
      return;
    }
    this._runtime.runCode(payload);
  }
}
