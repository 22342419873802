import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {SandboxComponent} from './pages/sandbox/sandbox.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'sandbox',
  },
  {
    path: 'sandbox',
    component: SandboxComponent,
    data: {
      title: 'Sandbox'
    },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
