import {Component, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {environment} from '../../../environments/environment';
import {DomSanitizer} from '@angular/platform-browser';
import {IRuntimeComponent} from '../../pages/sandbox/models';
import {IExecuteCodePayload} from '@exlinc/cc-web-exec-sdk/dist/models';

@Component({
  selector: 'app-codepan',
  templateUrl: './codepan.component.html',
  styleUrls: ['./codepan.component.scss']
})
export class CodepanComponent implements OnInit, OnDestroy, IRuntimeComponent {
  frameSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(environment.codepanBaseUrl);
  targetFrame;
  stopListening: () => void;
  @Input()
  language = 'javascript-browser';

  constructor(private domSanitizer: DomSanitizer, private renderer: Renderer2) {
    this.stopListening = renderer.listen('window', 'message', this.handleMessage.bind(this));
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.stopListening();
  }

  handleMessage(event: Event) {
    const message = event as MessageEvent;
    // Only trust messages from the below origin.
    if (message.origin !== environment.codepanBaseUrl) {
      console.log('origins mismatch: ', message.origin, ' <<<vs>>> ', environment.codepanBaseUrl);
      return;
    }
    console.log(message.data);
    if (!message.data || message.data.type !== 'codepan-ready') {
      return;
    }
    this.targetFrame = message.source;
  }

  getRuntimeType(): string {
    return 'codepan';
  }

  runCode(payload: IExecuteCodePayload): void {
    if (!this.targetFrame) {
      setTimeout(() => {
        this.runCode(payload);
      }, 100);
      return;
    }
    this.targetFrame.postMessage({
      type: 'codepan-set-boilerplate',
      // TODO fix the actual code boilerplate
      boilerplate: JSON.stringify(this.boilerplateFromExecPayload(payload)),
    }, '*');
  }

  boilerplateFromExecPayload(payload: IExecuteCodePayload) {
    return {
      js: {
        code: payload.defaultFileName.endsWith('.js') ? payload.files[payload.defaultFileName] : '',
        transformer: 'js',
      },
      css: {
        code: '', // We don't really have any plans for a 'css' language at the moment, omitting
        transformer: 'css',
      },
      html: {
        code: payload.defaultFileName.endsWith('.html') || payload.defaultFileName.endsWith('.php') ?
          payload.files[payload.defaultFileName] : '',
        transformer: 'html',
      },
      externalUserResources: payload.files,
      showPans: ['output', 'console'],
      activePan: 'output'
    };
  }
}
