import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {IRuntimeComponent} from '../../pages/sandbox/models';
import {IExecuteCodePayload} from '@exlinc/cc-web-exec-sdk/dist/models';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-karel',
  templateUrl: './karel.component.html',
  styleUrls: ['./karel.component.scss']
})
export class KarelComponent implements OnInit, IRuntimeComponent {
  langSlug = 'python';
  currentCode = '';
  frameSrc = null;
  // tslint:disable-next-line:variable-name
  _language = 'karel-python';
  @Input()
  set language(l: string) {
    this._setLang(l);
  }

  constructor(private domSanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
  }

  _setLang(l: string) {
    if (!l) {
      return;
    }
    this._language = l;
    this.langSlug = this._language.endsWith('python') ? 'python' : 'java';
  }

  getRuntimeType() {
    return 'karel';
  }

  runCode(payload: IExecuteCodePayload): void {
    // NOTE: Nasty patch to avoid an angular error about changing a value in the state while a change is in progress
    //       the setTimeout pops it out of the core angular call stack and seems to ensure that it
    //       is called at an appropriate time
    setTimeout(() => {
      this.currentCode = payload.files[payload.defaultFileName];
      this.frameSrc = this.getIFrameSrc();
    }, 0);
  }

  getIFrameSrc() {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`${environment.karelBaseUrl}karel-reader/docs/${this.langSlug}/en/ide.html?code=${encodeURIComponent(this.currentCode)}`);
  }

}
